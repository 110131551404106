import React, { useContext, useEffect, useState } from "react";
import { Carousel, Col, Card, Row, Container } from "react-bootstrap";
import { FaMapMarkerAlt, FaBed, FaRulerCombined, FaBath } from "react-icons/fa";
import { InfoContext } from "../providers/InfosContextProvider";
import { useNavigate } from "react-router-dom";

const CasasDestaque = () => {
    const { houses, texts } = useContext(InfoContext);
    const casasDestaque = houses.filter((casa) => casa.destaque);
    const navigate = useNavigate();

    const [imageIndex, setImageIndex] = useState({});
    const [columns, setColumns] = useState(3);

    const handleCardClick = (id) => {
        navigate(`/${id}`);
    };

    const chunkArray = (array, size) => {
        const result = [];
        for (let i = 0; i < array.length; i += size) {
            result.push(array.slice(i, i + size));
        }
        return result;
    };

    useEffect(() => {
        const updateColumns = () => {
            const width = window.innerWidth;
            if (width < 576) {
                setColumns(1);
            } else if (width < 768) {
                setColumns(2);
            } else {
                setColumns(3);
            }
        };

        window.addEventListener("resize", updateColumns);
        updateColumns();

        return () => window.removeEventListener("resize", updateColumns);
    }, []);

    const casasChunks = chunkArray(casasDestaque, columns);

    useEffect(() => {
        const intervals = casasChunks.flat().map((casa) => {
            return setInterval(() => {
                setImageIndex((prevState) => {
                    const newState = { ...prevState };
                    const currentIndex = prevState[casa.id] || 0;
                    const nextIndex = (currentIndex + 1) % casa.imagens.length;
                    newState[casa.id] = nextIndex;
                    return newState;
                });
            }, 1000 + ((Math.random() * 1000) % 1000));
        });

        return () => intervals.forEach(clearInterval);
    }, [casasChunks]);

    return (
        <div className="casas-destaque">
            <Row>
                <div className="titulo">
                    <h1 className="titulo-menor">{`${texts.destaque.tituloMenor}`}</h1>
                    <h1 className="titulo-maior">{`${texts.destaque.tituloMaior}`}</h1>
                    <div className="barra"></div>
                </div>
            </Row>

            <Container className="p-3">
                <Carousel
                    interval={null}
                    className="custom-carousel"
                    controls={casasDestaque.length > columns}
                    indicators={casasDestaque.length > columns}
                >
                    {casasChunks.map((chunk, index) => (
                        <Carousel.Item key={index}>
                            <Row className="mt-15">
                                {chunk.map((casa) => (
                                    <Col key={casa.id} xs={12} md={6} lg={4} className="mb-4">
                                        <Card className="casa-card">
                                            <Carousel interval={null} indicators={false}>
                                                {casa.imagens.map((imagem, index) => (
                                                    <Carousel.Item key={index}>
                                                        <div className="casa-imagem-container">
                                                            <img
                                                                src={`./imagens/casas/${imagem}`}
                                                                alt={`Casa ${casa.id}`}
                                                                onClick={() => handleCardClick(casa.id)}
                                                            />
                                                            <div className="casa-valor">{casa.valor}</div>
                                                        </div>
                                                    </Carousel.Item>
                                                ))}

                                                <div className="casas-container">
                                                    {casa.eficiencia.length === 1 ? (
                                                        <div className="casa-eficiencia">
                                                            <img
                                                                src={`./imagens/eficiencia/${casa.eficiencia[0]}_RGB.png`}
                                                                alt={`Eficiência Energética ${casa.eficiencia[0]}`}
                                                            />
                                                        </div>
                                                    ) : (
                                                        casa.eficiencia.map((imagem, index) => (
                                                            <Col key={index} xs={6} className="casa-eficiencia">
                                                                <img
                                                                    src={`./imagens/eficiencia/${imagem}_RGB.png`}
                                                                    alt={`Eficiência Energética ${imagem}`}
                                                                />
                                                            </Col>
                                                        ))
                                                    )}
                                                </div>
                                            </Carousel>

                                            <div className="casa-info">
                                                <Row>
                                                    <Col xs={12}>
                                                        <span>
                                                            <FaMapMarkerAlt /> {casa.localizacao}
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={4}>
                                                        <span>
                                                            <FaBed /> {casa.quartos || "-"}
                                                        </span>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <span>
                                                            <FaRulerCombined /> {casa.area || "-"}
                                                        </span>
                                                    </Col>
                                                    <Col xs={4}>
                                                        <span>
                                                            <FaBath /> {casa.casasDeBanho || "-"}
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Container>
        </div>
    );
};

export default CasasDestaque;
