import React, { useContext, useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { InfoContext } from "../providers/InfosContextProvider";

const Contactos = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get("id") ?? "";

    const { texts } = useContext(InfoContext);

    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);

    const [formData, setFormData] = useState({
        nome: "",
        email: "",
        telefone: "",
        mensagem: "",
    });

    useEffect(() => {
        if (id) {
            setFormData((prevData) => ({
                ...prevData,
                mensagem: `${texts.contactos.queroSaberMais}${id}.`,
            }));
        }
    }, [id, texts.contactos.queroSaberMais]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Envia os dados do formulário para o script PHP
        fetch("./src/infoEmail.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams(formData).toString(),
        })
            .then((response) => {
                if (response.status === 200) {
                    setShowAlert(true);
                    setShowError(false);
                } else {
                    setShowAlert(false);
                    setShowError(true);
                }
            })
            .catch(() => {
                setShowAlert(false);
                setShowError(true);
            });
    };

    return (
        <div>
            <Header />
            <Container className="vender-casa">
                <h2>{texts.contactos.titulo}</h2>
                <p>{texts.contactos.subtitulo}</p>
                {showAlert && (
                    <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                        {texts.contactos.msgOK}
                    </Alert>
                )}
                {showError && (
                    <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                        {texts.contactos.msgErro}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="nome">
                                <Form.Label>{texts.contactos.nome}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nome"
                                    placeholder={texts.contactos.nomePlaceholder}
                                    value={formData.nome}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="email">
                                <Form.Label>{texts.contactos.email}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder={texts.contactos.emailPlaceholder}
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="telefone">
                                <Form.Label>{texts.contactos.telefone}</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="telefone"
                                    placeholder={texts.contactos.telefonePlaceholder}
                                    value={formData.telefone}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="mensagem">
                        <Form.Label>{texts.contactos.mensagem}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            name="mensagem"
                            placeholder={texts.contactos.mensagemPlaceholder}
                            value={formData.mensagem}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Button className="mt-3 btn-vender" type="submit">
                        {texts.contactos.enviar}
                    </Button>
                </Form>
            </Container>
            <div className="div-footer">
                <Footer />
            </div>
        </div>
    );
};

export default Contactos;
