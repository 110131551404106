export const customTheme = (theme) => ({
    ...theme,
    colors: {
        ...theme.colors,
        primary50: "#e0e0e0",
        primary: "#d3d3d3",
        primary25: "#f0f0f0",
        neutral0: "#ffffff",
        neutral20: "#d3d3d3",
        neutral30: "#bfbfbf",
        neutral40: "#d3d3d3",
        neutral50: "#a9a9a9",
        neutral60: "#808080",
        neutral80: "#000000",
    },
});
