import React from "react";
import logo from "./transparente.png";

const Logo = () => {
    return (
        <div className="preloader">
            <img src={logo} alt="Logo" className="preloader-logo" />
        </div>
    );
};

export default Logo;
