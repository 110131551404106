import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { InfosContextProvider } from "./providers/InfosContextProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import { App } from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <InfosContextProvider>
            <App />
        </InfosContextProvider>
    </React.StrictMode>
);
