import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/Header";
import Pesquisa from "./components/Pesquisa";
import CasasDestaque from "./components/CasasDestaque";
import Footer from "./components/Footer";
import React from "react";

function Home() {
    return (
        <>
            <Header />
            <div className="fundo">
                <Pesquisa />
                <CasasDestaque />
            </div>
            <Footer />
        </>
    );
}

export default Home;
