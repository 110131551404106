import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaInstagram, FaEnvelope, FaPhone, FaMapMarkerAlt } from "react-icons/fa";
import { InfoContext } from "../providers/InfosContextProvider";

const Footer = () => {
    const { texts } = useContext(InfoContext);
    return (
        <footer className="text-black py-4">
            <Container>
                <Row className="d-flex justify-content-between align-items-center">
                    <Col xs={12} md="auto" className="mb-3 mb-md-0">
                        <div>
                            <p className="mb-2">
                                <FaEnvelope className="me-2" />
                                <a href={`mailto:${texts.footer.email}`} className="text-black">
                                    {texts.footer.email}
                                </a>
                            </p>
                            <p className="mb-2">
                                <FaPhone className="me-2" />
                                <a href={`tel:${texts.footer.tel2}`} className="text-black">
                                    {texts.footer.tel}
                                </a>
                            </p>
                            <p className="mb-0">
                                <FaMapMarkerAlt className="me-2" />
                                <a
                                    href="https://www.google.com/maps/search/?api=1&query=Rua+Silva+Magalhães+14,+Tomar,+Portugal"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-black"
                                >
                                    {texts.footer.morada}
                                </a>
                            </p>
                        </div>
                    </Col>

                    <Col xs={12} md="auto" className="d-flex justify-content-center justify-content-md-end">
                        <div className="d-flex">
                            <a
                                href={`${texts.footer.facebook}`}
                                className="text-black me-3"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaFacebook size={20} />
                            </a>
                            <a
                                href={`${texts.footer.insta}`}
                                className="text-black me-3"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <FaInstagram size={20} />
                            </a>
                        </div>
                    </Col>
                </Row>

                <Row className="pt-3 border-top mt-3">
                    <Col className="text-center">
                        <p className="mb-0">{`${texts.footer.copyright}`}</p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

export default Footer;
