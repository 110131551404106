import React, { useState, useContext } from "react";
import { Form, Button, Row, Col, Card, Carousel, Container } from "react-bootstrap";
import { FaTrash, FaBed, FaBath, FaRulerCombined, FaMapMarkerAlt } from "react-icons/fa";
import Select from "react-select";
import Header from "./Header";
import Footer from "./Footer";
import { InfoContext } from "../providers/InfosContextProvider";
import { customTheme } from "./dropdown";
import { useNavigate } from "react-router-dom";

const Imoveis = () => {
    const [selectedTransacao, setSelectedTransacao] = useState(null);
    const [selectedTipo, setSelectedTipo] = useState(null);
    const [selectedQuartos, setSelectedQuartos] = useState(null);
    const [selectedWc, setSelectedWc] = useState(null);
    const [selectedFilter, setSelectedFilter] = useState(null);

    const { options, houses, texts } = useContext(InfoContext);
    const navigate = useNavigate();

    const clearAllSelections = () => {
        setSelectedFilter(null);
        setSelectedTransacao(null);
        setSelectedTipo(null);
        setSelectedQuartos(null);
        setSelectedWc(null);
    };

    const handleCardClick = (id) => {
        navigate(`/${id}`);
    };

    const sortCasas = (casas, filter) => {
        switch (filter) {
            case "recente":
                return casas.sort((a, b) => b.id - a.id);
            case "antigo":
                return casas.sort((a, b) => a.id - b.id);
            case "precoAsc":
                return casas.sort(
                    (a, b) =>
                        parseFloat(a.valor.replace("€", "").replace(",", "")) -
                        parseFloat(b.valor.replace("€", "").replace(",", ""))
                );
            case "precoDesc":
                return casas.sort(
                    (a, b) =>
                        parseFloat(b.valor.replace("€", "").replace(",", "")) -
                        parseFloat(a.valor.replace("€", "").replace(",", ""))
                );
            default:
                return casas;
        }
    };

    const filterCasas = (casas) => {
        let filteredCasas = casas;

        if (selectedTransacao) {
            filteredCasas = filteredCasas.filter((casa) => casa.transacao === selectedTransacao.value);
        }
        if (selectedTipo) {
            filteredCasas = filteredCasas.filter((casa) => casa.tipoImovel === selectedTipo.value);
        }
        if (selectedQuartos) {
            filteredCasas = filteredCasas.filter((casa) => casa.quartos === Number(selectedQuartos.value));
        }
        if (selectedWc) {
            filteredCasas = filteredCasas.filter((casa) => casa.casasDeBanho === Number(selectedWc.value));
        }

        return filteredCasas;
    };

    const filteredCasas = filterCasas(houses);

    return (
        <>
            <Header />
            <div className="fundo pesquisa-container">
                <div className="pesquisa-content">
                    <Form>
                        <Row className="align-items-end">
                            <Col className="inputs" md={6} lg={3}>
                                <Form.Group controlId="tipo-transacao">
                                    <Select
                                        options={options.transacao}
                                        value={selectedTransacao}
                                        onChange={setSelectedTransacao}
                                        theme={customTheme}
                                        placeholder={`${texts.pesquisa.transacao}`}
                                        isSearchable={false}
                                    />
                                </Form.Group>
                            </Col>

                            <Col className="inputs" md={6} lg={3}>
                                <Form.Group controlId="tipo-imovel">
                                    <Select
                                        options={options.tipo}
                                        value={selectedTipo}
                                        onChange={setSelectedTipo}
                                        theme={customTheme}
                                        placeholder={`${texts.pesquisa.tipo}`}
                                        isSearchable={false}
                                    />
                                </Form.Group>
                            </Col>

                            <Col className="inputs" md={6} lg={3}>
                                <Form.Group controlId="quartos">
                                    <Select
                                        options={options.quartos}
                                        value={selectedQuartos}
                                        onChange={setSelectedQuartos}
                                        theme={customTheme}
                                        placeholder={`${texts.pesquisa.quartos}`}
                                        isSearchable={false}
                                    />
                                </Form.Group>
                            </Col>

                            <Col className="inputs" md={6} lg={3}>
                                <Form.Group controlId="casas-de-banho">
                                    <Select
                                        options={options.wc}
                                        value={selectedWc}
                                        onChange={setSelectedWc}
                                        theme={customTheme}
                                        placeholder={`${texts.pesquisa.casasDeBanho}`}
                                        isSearchable={false}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Col md={12} lg={12} className="text-right">
                            <div className="icone-pesquisa">
                                <Button className="btn-limpar" onClick={clearAllSelections}>
                                    <FaTrash /> {`${texts.pesquisa.limpar}`}
                                </Button>
                            </div>
                        </Col>
                    </Form>
                </div>
            </div>

            <Container className="p-3">
                <div className="resultados">
                    <Row className="resultados-container">
                        <Col xs={6} md={6} className="text-start mb-2 mb-md-0">
                            <span className="bold">{filteredCasas.length}</span>
                            {`${texts.resultados.resultado}`}
                        </Col>
                        <Col xs={0} md={4} className="d-none d-md-block"></Col>
                        <Col xs={6} md={2} className="text-end ordenar">
                            <Form.Group controlId="ordenar">
                                <Select
                                    options={options.ordenar}
                                    value={selectedFilter}
                                    onChange={setSelectedFilter}
                                    theme={customTheme}
                                    placeholder={`${texts.resultados.ordenar}`}
                                    isSearchable={false}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </div>
                <Row>
                    {sortCasas(filteredCasas, selectedFilter ? selectedFilter.value : null).map((casa) => (
                        <Col key={casa.id} xs={12} md={6} lg={4} className="mb-4">
                            <Card className="casa-card">
                                <Carousel interval={null} indicators={false}>
                                    {casa.imagens.map((imagem, index) => (
                                        <Carousel.Item key={index}>
                                            <div className="casa-imagem-container">
                                                <img
                                                    src={`./imagens/casas/${imagem}`}
                                                    alt={`Casa ${casa.id}`}
                                                    onClick={() => handleCardClick(casa.id)}
                                                />
                                                <div className="casa-valor">{casa.valor}</div>
                                            </div>
                                        </Carousel.Item>
                                    ))}

                                    <div className="casas-container">
                                        {casa.eficiencia.length === 1 ? (
                                            <div className="casa-eficiencia">
                                                <img
                                                    src={`./imagens/eficiencia/${casa.eficiencia[0]}_RGB.png`}
                                                    alt={`Eficiência Energética ${casa.eficiencia[0]}`}
                                                />
                                            </div>
                                        ) : (
                                            casa.eficiencia.map((imagem, index) => (
                                                <Col key={index} xs={6} className="casa-eficiencia">
                                                    <img
                                                        src={`./imagens/eficiencia/${imagem}_RGB.png`}
                                                        alt={`Eficiência Energética ${imagem}`}
                                                    />
                                                </Col>
                                            ))
                                        )}
                                    </div>
                                </Carousel>

                                <div className="casa-info">
                                    <Row>
                                        <Col xs={12}>
                                            <span>
                                                <FaMapMarkerAlt /> {casa.localizacao}
                                            </span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={4}>
                                            <span>
                                                <FaBed /> {casa.quartos || "-"}
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span>
                                                <FaRulerCombined /> {casa.area || "-"}
                                            </span>
                                        </Col>
                                        <Col xs={4}>
                                            <span>
                                                <FaBath /> {casa.casasDeBanho || "-"}
                                            </span>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default Imoveis;
