import React, { useContext, useState } from "react";
import { Form, Button, Container, Row, Col, Alert } from "react-bootstrap";
import Header from "./Header";
import Footer from "./Footer";
import { InfoContext } from "../providers/InfosContextProvider";

const VendaCasa = () => {
    const { texts } = useContext(InfoContext);
    const [formData, setFormData] = useState({
        nome: "",
        email: "",
        telefone: "",
        mensagem: "",
    });
    const [showAlert, setShowAlert] = useState(false);
    const [showError, setShowError] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        fetch("./src/infoEmail.php", {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            body: new URLSearchParams(formData).toString(),
        })
            .then((response) => {
                if (response.status === 200) {
                    setShowAlert(true);
                    setShowError(false);
                    setFormData({
                        nome: "",
                        email: "",
                        telefone: "",
                        mensagem: "",
                    });
                } else {
                    setShowAlert(false);
                    setShowError(true);
                }
            })
            .catch((error) => {
                console.error("Erro:", error);
                setShowAlert(false);
                setShowError(true);
            });
    };

    return (
        <>
            <Header />
            <Container className="vender-casa">
                <h2>{`${texts.venderCasa.titulo}`}</h2>
                <p>{`${texts.venderCasa.subtitulo}`}</p>
                {showAlert && (
                    <Alert variant="success" onClose={() => setShowAlert(false)} dismissible>
                        {`${texts.venderCasa.msgOK}`}
                    </Alert>
                )}
                {showError && (
                    <Alert variant="danger" onClose={() => setShowError(false)} dismissible>
                        {`${texts.venderCasa.msgErro}`}
                    </Alert>
                )}
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="nome">
                                <Form.Label>{`${texts.venderCasa.nome}`}</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="nome"
                                    placeholder={`${texts.venderCasa.nomePlaceholder}`}
                                    value={formData.nome}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="email">
                                <Form.Label>{`${texts.venderCasa.email}`}</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder={`${texts.venderCasa.emailPlaceholder}`}
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Row>
                        <Col md={6}>
                            <Form.Group controlId="telefone">
                                <Form.Label>{`${texts.venderCasa.telefone}`}</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="telefone"
                                    placeholder={`${texts.venderCasa.telefonePlaceholder}`}
                                    value={formData.telefone}
                                    onChange={handleChange}
                                    required
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Form.Group controlId="mensagem">
                        <Form.Label>{`${texts.venderCasa.mensagem}`}</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={5}
                            name="mensagem"
                            placeholder={`${texts.venderCasa.mensagemPlaceholder}`}
                            value={formData.mensagem}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Button className="mt-3 btn-vender" type="submit">
                        {`${texts.venderCasa.enviar}`}
                    </Button>
                </Form>
            </Container>
            <div className="div-footer">
                <Footer />
            </div>
        </>
    );
};

export default VendaCasa;
