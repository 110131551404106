import React, { useState, useContext } from "react";
import { Row, Col, Card, Carousel, Modal, Button } from "react-bootstrap";
import { FaUser, FaPhone, FaEnvelope, FaBed, FaBath, FaRulerCombined, FaMapMarkerAlt } from "react-icons/fa";
import Header from "./Header";
import Footer from "./Footer";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { InfoContext } from "../providers/InfosContextProvider";
import { useNavigate } from "react-router-dom";

const InfoCasa = () => {
    const [firstTime, setFirstTime] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [modalImageIndex, setModalImageIndex] = useState(0);
    const [currentCasa, setCurrentCasa] = useState(null);
    const [isCarouselPaused, setIsCarouselPaused] = useState(false);

    const [ok, setOk] = useState(false);
    const { houses, texts } = useContext(InfoContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (houses.length !== 0 && firstTime) {
            setOk(true);
            setFirstTime(false);
        }
    }, [firstTime, houses.length]);

    const { id } = useParams();
    const casa = houses.find((c) => c.id === parseInt(id));

    const handleOpenModal = (index, casaId) => {
        setModalImageIndex(index);
        setCurrentCasa(casa);
        setIsCarouselPaused(true);

        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setIsCarouselPaused(false);
    };

    const handleMsg = (id) => {
        navigate(`/contacto?id=${id}`);
    };

    return (
        <>
            {ok && (
                <>
                    <Header />
                    <div className="casa-detalhes-container">
                        <Row>
                            <Col xs={12} md={6} className="fixed-col">
                                <Card className="casa-detalhes-card">
                                    <Carousel interval={isCarouselPaused ? null : 3000}>
                                        {casa.imagens.map((imagem, index) => (
                                            <Carousel.Item key={index}>
                                                <div
                                                    className="carousel-image-container"
                                                    onClick={() => handleOpenModal(index, casa.id)}
                                                >
                                                    <img
                                                        className="d-block w-100"
                                                        src={`./imagens/casas/${imagem}`}
                                                        alt={`Slide ${index}`}
                                                    />
                                                </div>
                                            </Carousel.Item>
                                        ))}
                                    </Carousel>
                                    <Card.Body>
                                        <Card.Title>
                                            <div className="casa-detalhes-eficiencia">
                                                {casa.valor}
                                                {casa.eficiencia.map((eficiencia, index) => (
                                                    <img
                                                        key={index}
                                                        src={`./imagens/eficiencia/${eficiencia}_RGB.png`}
                                                        alt={`Eficiência Energética ${eficiencia}`}
                                                    ></img>
                                                ))}
                                            </div>
                                        </Card.Title>
                                        <Row>
                                            <Col className="text-center">
                                                <span>
                                                    <FaMapMarkerAlt /> {casa.localizacao}
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="text-center">
                                                <span>
                                                    <FaBed /> {casa.quartos || "-"}
                                                </span>
                                            </Col>
                                            <Col className="text-center">
                                                <span>
                                                    <FaRulerCombined /> {casa.area || "-"}
                                                </span>
                                            </Col>
                                            <Col className="text-center">
                                                <span>
                                                    <FaBath /> {casa.casasDeBanho || "-"}
                                                </span>
                                            </Col>
                                        </Row>
                                        <div className="casa-contactos">
                                            <h3>{`${texts.infoCasa.contactos}`}</h3>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <p>
                                                        <FaUser /> {casa.contactos.nome}
                                                    </p>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <p>
                                                        <FaPhone /> {casa.contactos.telefone}
                                                    </p>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={12} md={6}>
                                                    <p>{casa.contactos.email}</p>
                                                </Col>
                                                <Col xs={12} md={6}>
                                                    <Button className="btn-vender" onClick={() => handleMsg(casa.id)}>
                                                        {`${texts.infoCasa.enviarMsg}`}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6} className="fixed-col">
                                <div className="casa-descricao">
                                    <h3>{`${texts.infoCasa.descricao}`}</h3>
                                    <p>
                                        {casa.descricao.split("\n").map((line, index) => (
                                            <React.Fragment key={index}>
                                                {line}
                                                <br />
                                            </React.Fragment>
                                        ))}
                                    </p>

                                    <p>
                                        {`${texts.infoCasa.ref}`} {casa.id}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </div>

                    <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                        <Carousel activeIndex={modalImageIndex} onSelect={(selectedIndex) => setModalImageIndex(selectedIndex)}>
                            {currentCasa
                                ? currentCasa.imagens.map((imagem, index) => (
                                      <Carousel.Item key={index}>
                                          <div
                                              className="carousel-image-container"
                                              onClick={() => handleOpenModal(index, currentCasa.id)}
                                          >
                                              <img
                                                  className="d-block w-100"
                                                  src={`./imagens/casas/${imagem}`}
                                                  alt={`Slide ${index}`}
                                              />
                                          </div>
                                      </Carousel.Item>
                                  ))
                                : casa.imagens.map((imagem, index) => (
                                      <Carousel.Item key={index}>
                                          <div
                                              className="carousel-image-container"
                                              onClick={() => handleOpenModal(index, casa.id)}
                                          >
                                              <img
                                                  className="d-block w-100"
                                                  src={`./imagens/casas/${imagem}`}
                                                  alt={`Slide ${index}`}
                                              />
                                          </div>
                                      </Carousel.Item>
                                  ))}
                        </Carousel>
                    </Modal>

                    <Footer />
                </>
            )}
        </>
    );
};

export default InfoCasa;
