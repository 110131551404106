import React, { useEffect, useState, createContext } from "react";
import Logo from "../components/Logo.js";
import Erro from "../components/Erro.js";

export const InfoContext = createContext(undefined);

export const InfosContextProvider = ({ children }) => {
    const [options, setOptions] = useState({
        transacao: [],
        tipo: [],
        quartos: [],
        wc: [],
        ordenar: [],
    });
    const [houses, setHouses] = useState([]);
    const [texts, setTexts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [language, setLanguage] = useState("PT");

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [textsResponse, housesResponse, optionsResponse] = await Promise.all([
                    fetch(`./src/getTextos${language}.php`),
                    fetch(`./src/getCasas${language}.php`),
                    fetch(`./src/getPesquisa${language}.php`),
                ]);

                if (!textsResponse.ok || !housesResponse.ok || !optionsResponse.ok) {
                    throw new Error("Failed to fetch data");
                }

                const textsData = await textsResponse.json();
                const housesData = await housesResponse.json();
                const optionsData = await optionsResponse.json();

                setTexts(textsData);
                setHouses(housesData);
                setOptions(optionsData);
                setLoading(false);
                setError(false);
            } catch {
                setLoading(false);
                setError(true);
            }
        };

        setLoading(true);
        fetchData();
    }, [language]);

    if (loading) {
        return <Logo />;
    }

    if (error) {
        return <Erro />;
    }

    return <InfoContext.Provider value={{ options, houses, texts, language, setLanguage }}>{children}</InfoContext.Provider>;
};
