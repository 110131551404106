import React, { useContext } from "react";
import logo from "./transparente.png";
import { InfoContext } from "../providers/InfosContextProvider";
import Header from "./Header";
import Footer from "./Footer";

const Sobre = () => {
    const { texts } = useContext(InfoContext);
    return (
        <>
            <Header />
            <div className="sobre-nos-container">
                <div className="sobre-nos-logo">
                    <img src={logo} alt="Logo da Empresa" />
                </div>
                <div className="sobre-nos-text">
                    <p>{`${texts.sobreNos.descricao}`}</p>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Sobre;
