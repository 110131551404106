import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Home";
import VendaCasa from "./components/VendaCasa";
import Contactos from "./components/Contactos";
import Resultados from "./components/Resultados";
import InfoCasa from "./components/InfoCasa";
import Imoveis from "./components/Imoveis";
import Sobre from "./components/Sobre";
import Erro from "./components/Erro";

export const App = () => {
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/contacto" element={<Contactos />} />
                <Route path="/venda-a-sua-casa" element={<VendaCasa />} />
                <Route path="/resultados" element={<Resultados />} />
                <Route path="/:id" element={<InfoCasa />} />
                <Route path="/contacto/:id" element={<Contactos />} />
                <Route path="/imoveis" element={<Imoveis />} />
                <Route path="/sobre" element={<Sobre />} />
                <Route path="/teste" element={<Erro />} />
            </Routes>
        </Router>
    );
};
