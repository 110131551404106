import React, { useContext } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "./logoHeader.png";
import logo1 from "./logo.png";
import { InfoContext } from "../providers/InfosContextProvider";
import portugalFlag from "./pt.png";
import enFlag from "./en.png";
import frFlag from "./fr.png";

const Header = () => {
    const { texts, language, setLanguage } = useContext(InfoContext);

    return (
        <header className="header fixed-top">
            <Navbar expand="lg" className="d-flex align-items-center">
                <Container fluid>
                    <Navbar.Brand href="/" className="d-flex align-items-center">
                        <img className="logo1" src={logo1} alt="Logo" />
                        <img className="logo" src={logo} alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="navmenu" />
                    <Navbar.Collapse id="navmenu">
                        <Nav className="ms-auto">
                            <Nav.Link as={Link} to="/" className="nav-link active">
                                {texts.header.primeiro}
                            </Nav.Link>

                            <Nav.Link as={Link} to="/imoveis" className="nav-link active">
                                {texts.header.segundo}
                            </Nav.Link>

                            <Nav.Link as={Link} to="/contacto" className="nav-link">
                                {texts.header.terceiro}
                            </Nav.Link>

                            <Nav.Link as={Link} to="/venda-a-sua-casa" className="nav-link">
                                {texts.header.quarto}
                            </Nav.Link>

                            <Nav.Link as={Link} to="/sobre" className="nav-link">
                                {texts.header.quinto}
                            </Nav.Link>

                            <Dropdown align="end">
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    {language === "PT" ? (
                                        <img src={portugalFlag} alt={`${texts.header.pt}`} width="20" height="20" />
                                    ) : language === "EN" ? (
                                        <img src={enFlag} alt={`${texts.header.en}`} width="20" height="20" />
                                    ) : (
                                        ""
                                    )}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => setLanguage("PT")}>
                                        <img src={portugalFlag} alt={`${texts.header.pt}`} width="20" height="20" />
                                        {` ${texts.header.pt}`}
                                    </Dropdown.Item>
                                    <Dropdown.Item onClick={() => setLanguage("EN")}>
                                        <img src={enFlag} alt={`${texts.header.en}`} width="20" height="20" />
                                        {` ${texts.header.en}`}
                                    </Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
};

export default Header;
