import React, { useState } from "react";
import logo from "./transparente.png";
import { FaEnvelope } from "react-icons/fa";
import { Dropdown } from "react-bootstrap";
import portugalFlag from "./pt.png";
import enFlag from "./en.png";

const Erro = () => {
    const [language, setLanguage] = useState("PT");

    return (
        <>
            <div className="error-page-wrapper">
                <div className="error-page-container">
                    <Dropdown align="end">
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            {language === "PT" ? (
                                <img src={portugalFlag} alt="pt" width="20" height="20" />
                            ) : (
                                <img src={enFlag} alt="en" width="20" height="20" />
                            )}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => setLanguage("PT")}>
                                <img src={portugalFlag} alt="pt" width="20" height="20" /> PT
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => setLanguage("EN")}>
                                <img src={enFlag} alt="en" width="20" height="20" /> EN
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <img src={logo} alt="Logo" className="error-page-logo" />
                    <div className="error-message">
                        {language === "PT" ? (
                            <>
                                <h1>Ocorreu um erro</h1>
                                <p>Por favor, tente novamente mais tarde.</p>
                                <p>Se o erro persistir, por favor contacte-nos. Pedimos desculpa pelos incómodos causados.</p>
                            </>
                        ) : (
                            <>
                                <h1>An error occurred</h1>
                                <p>Please try again later.</p>
                                <p>If the error persists, please contact us. We apologize for any inconvenience caused.</p>
                            </>
                        )}
                        <p className="mb-2">
                            <FaEnvelope className="me-2" />
                            <a href={`mailto:investimentoscasasdonabao@gmail.com`} className="text-black">
                                investimentoscasasdonabao@gmail.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Erro;
