import React, { useState, useContext, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { FaSearch, FaTrash } from "react-icons/fa";
import Select from "react-select";
import { customTheme } from "./dropdown";
import { InfoContext } from "../providers/InfosContextProvider";
import { useNavigate } from "react-router-dom";

const Pesquisa = () => {
    const [selectedTransacao, setSelectedTransacao] = useState(null);
    const [selectedTipo, setSelectedTipo] = useState(null);
    const [selectedQuartos, setSelectedQuartos] = useState(null);
    const [selectedWc, setSelectedWc] = useState(null);

    const { options, texts, language } = useContext(InfoContext);
    const [filteredOptions, setFilteredOptions] = useState(options);

    const navigate = useNavigate();

    const clearAllSelections = () => {
        setSelectedTransacao(null);
        setSelectedTipo(null);
        setSelectedQuartos(null);
        setSelectedWc(null);
    };

    const handleSearch = () => {
        const params = {};

        if (selectedTransacao && selectedTransacao.value) {
            params.transacao = selectedTransacao.value;
        }

        if (selectedTipo && selectedTipo.value) {
            params.tipo = selectedTipo.value;
        }

        if (selectedQuartos && selectedQuartos.value) {
            params.quartos = selectedQuartos.value;
        }

        if (selectedWc && selectedWc.value) {
            params.wc = selectedWc.value;
        }

        const queryParams = new URLSearchParams(params).toString();

        navigate(`/resultados?${queryParams}`);
    };

    useEffect(() => {
        const updateOptions = () => {
            setFilteredOptions(options);
        };

        updateOptions();
    }, [language, options]);

    return (
        <div className="pesquisa-container">
            <div className="pesquisa-content">
                <Form>
                    <Row className="align-items-end">
                        <Col className="inputs" md={6} lg={3}>
                            <Form.Group controlId="tipo-transacao">
                                <Select
                                    options={filteredOptions.transacao}
                                    value={selectedTransacao}
                                    onChange={setSelectedTransacao}
                                    theme={customTheme}
                                    placeholder={`${texts.pesquisa.transacao}`}
                                    isSearchable={false}
                                />
                            </Form.Group>
                        </Col>

                        <Col className="inputs" md={6} lg={3}>
                            <Form.Group controlId="tipo-imovel">
                                <Select
                                    options={filteredOptions.tipo}
                                    value={selectedTipo}
                                    onChange={setSelectedTipo}
                                    theme={customTheme}
                                    placeholder={`${texts.pesquisa.tipo}`}
                                    isSearchable={false}
                                />
                            </Form.Group>
                        </Col>

                        <Col className="inputs" md={6} lg={3}>
                            <Form.Group controlId="quartos">
                                <Select
                                    options={filteredOptions.quartos}
                                    value={selectedQuartos}
                                    onChange={setSelectedQuartos}
                                    theme={customTheme}
                                    placeholder={`${texts.pesquisa.quartos}`}
                                    isSearchable={false}
                                />
                            </Form.Group>
                        </Col>

                        <Col className="inputs" md={6} lg={3}>
                            <Form.Group controlId="casas-de-banho">
                                <Select
                                    options={filteredOptions.wc}
                                    value={selectedWc}
                                    onChange={setSelectedWc}
                                    theme={customTheme}
                                    placeholder={`${texts.pesquisa.casasDeBanho}`}
                                    isSearchable={false}
                                />
                            </Form.Group>
                        </Col>
                    </Row>

                    <Col md={12} lg={12} className="text-right" onClick={handleSearch}>
                        <div className="icone-pesquisa">
                            <Button>
                                <FaSearch /> {`${texts.pesquisa.pesquisar}`}
                            </Button>

                            <Button className="btn-limpar" onClick={clearAllSelections}>
                                <FaTrash /> {`${texts.pesquisa.limpar}`}
                            </Button>
                        </div>
                    </Col>
                </Form>
            </div>
        </div>
    );
};

export default Pesquisa;
